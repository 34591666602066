/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { elb } from '@elbwalker/walker.js';
import type { UserAttributes } from '@optimizely/optimizely-sdk';
import { LogLevel as OptimizelyLogLevel } from '@optimizely/optimizely-sdk';
import { createInstance, type ReactSDKClient } from '@optimizely/react-sdk';
import type { ISharedIframeStorage } from '@sumup/shared-iframe-storage';
import bowser from 'bowser';
import Cookie from 'js-cookie';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_COUNTRY_CODE } from 'shared/constants/CountryConstants';
import { getCookie } from 'shared/infra/cookies';
import logger, {
  type LogLevel as LoggerLogLevel,
} from 'shared/services/logger/logger';
import {
  EXPERIMENT_USER_COOKIE_NAME,
  IN_APP_PARAM,
  MISSING_VALUE,
  PLATFORM,
  SUMUP_COOKIE_DOMAIN,
} from 'shared/services/optimizely/constants';
import {
  dispatchOptimizelyInitEvent,
  dispatchOptimizelyInitFailureEvent,
} from 'shared/services/tracker/events';
import { getChannelFromSource } from 'shared/utils/channel-link';
import { getLast } from 'utils/querystring';
import { getActiveConsentCategories } from 'utils/scripts/cookieConsent/cookieConsent';

import { EVENTS } from '../walker/constants';

declare global {
  interface Window {
    gaGlobal?: { vid?: string };
  }
}

interface GAOptimizelyEvent {
  experiment?: string;
  variation?: string;
}

const EXPERIMENT_COOKIES_MAX_AGE = 365;
const SHARED_IFRAME_STORAGE_URL =
  'https://static.sumup.com/shared-iframe-storage/index.html';

let SharedIframeStorage: ISharedIframeStorage;

export const createSharedIframeStorage = async (): Promise<void> => {
  if (!SharedIframeStorage) {
    SharedIframeStorage = await import('@sumup/shared-iframe-storage');

    try {
      // connect to shared localStorage between domains
      await SharedIframeStorage.connect(SHARED_IFRAME_STORAGE_URL);
    } catch (err) {
      // fail silently
    }
  }
};

const customErrorHandler = {
  handleError: (error): void => {
    logger.error(error);
    void dispatchOptimizelyInitFailureEvent();
  },
};

const LOGGER_TO_OPTIMIZELY_LOG_LEVEL: Record<
  LoggerLogLevel,
  OptimizelyLogLevel
> = {
  debug: OptimizelyLogLevel.DEBUG,
  info: OptimizelyLogLevel.INFO,
  warn: OptimizelyLogLevel.WARNING,
  error: OptimizelyLogLevel.ERROR,
};

/**
 * Generates a new instance of the Optimizely SDK.
 * Avoid creating multiple instances inside the App, which
 * might cause event triggering issues and misattributions.
 */
export const getSDKClient = (): ReactSDKClient => {
  void dispatchOptimizelyInitEvent();
  const instance = createInstance({
    sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_KEY,
    errorHandler: customErrorHandler,
    logLevel: LOGGER_TO_OPTIMIZELY_LOG_LEVEL[process.env.NEXT_PUBLIC_LOG_LEVEL],
  });

  const originalTrackFn = instance.track.bind(instance);
  // We're only allowed to track users that have given their consent,
  // thus we override Optimizely's track fn to check consent before tracking.
  instance.track = (...args) => {
    const categories = getActiveConsentCategories();

    if (categories.includes('FUNCTIONAL')) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      originalTrackFn(...args);
    }
  };

  return instance;
};

export const optimizely = getSDKClient();

export const isOptimizelyReady = (): Promise<unknown> => optimizely.onReady();

export const getUserId = (): string => {
  const userId =
    Cookie.get(EXPERIMENT_USER_COOKIE_NAME) ||
    // check existing experiment user id in shared localStorage between domains
    SharedIframeStorage?.getItem(EXPERIMENT_USER_COOKIE_NAME) ||
    // generate a new unique user id if there is no existing one
    uuidv4();

  Cookie.set(EXPERIMENT_USER_COOKIE_NAME, userId, {
    expires: EXPERIMENT_COOKIES_MAX_AGE,
    domain: SUMUP_COOKIE_DOMAIN,
  });

  SharedIframeStorage?.setItem(EXPERIMENT_USER_COOKIE_NAME, userId, {
    expires: EXPERIMENT_COOKIES_MAX_AGE,
  });

  return userId;
};

/**
 * Getting Google Analytics user id from cookies or
 * global GA object
 */
const getGAUserId = (): string | null =>
  Cookie.get('_ga') || window?.gaGlobal?.vid || null;

export const getAttributes = (
  shopCountry = DEFAULT_COUNTRY_CODE,
): UserAttributes => {
  const { search, pathname } = window.location;
  const { referrer } = queryString.parse(search);
  const isUserFromApp = search.includes(IN_APP_PARAM);
  const channel = getChannelFromSource();

  const browserInfo = bowser.parse(navigator.userAgent);
  const browserName = browserInfo?.browser?.name;
  const browserVersion = browserInfo?.browser?.version;
  const deviceType = browserInfo?.platform?.type;
  const osName = browserInfo?.os?.name;
  const osVersion = browserInfo?.os?.version;
  const browserLanguage =
    (navigator.languages && navigator.languages[0]) || // chrome / firefox
    navigator.language; // other browsers
  const cookiestring = Object.entries(Cookie.get())
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return {
    pathname,
    channel,
    cookiestring,
    'shop_country': shopCountry,
    'querystring': search,
    'from_app': isUserFromApp,
    'referrer_param': getLast(referrer) || null,
    'google-analytics-user-id': getGAUserId(),
    'platform': PLATFORM,
    'user-agent': navigator.userAgent,
    'browser-language': browserLanguage,
    'browser-name': browserName,
    'browser-version': browserVersion ?? MISSING_VALUE,
    'device-type': deviceType ?? MISSING_VALUE,
    'os-name': osName,
    'os-version': osVersion ?? MISSING_VALUE,
  };
};

export const hasExperimentTriggeredToGA = (
  event: GAOptimizelyEvent,
  // since the datafile object is of unknown type, we need to type it as any
  dataLayer: Record<string, any>[] = [],
): boolean =>
  dataLayer.some((item) => {
    if (!item.customParameters || item.event !== 'optimizely') {
      return false;
    }

    return (
      event.experiment === item.customParameters.optimizelyExperimentKey &&
      event.variation === item.customParameters.optimizelyVariationKey
    );
  });

export const handleActivate = ({
  experiment = {},
  variation = {},
  userId = '',
}: any = {}): void => {
  window.dataLayer = window.dataLayer || [];

  const event = { experiment: experiment.key, variation: variation.key };
  if (hasExperimentTriggeredToGA(event, window.dataLayer)) {
    return;
  }

  window.dataLayer.push({
    event: 'optimizely',
    customParameters: {
      optimizelyExperimentKey: experiment.key,
      optimizelyVariationKey: variation.key,
    },
  });

  elb(EVENTS.OPTIMIZELY_EXPERIMENT_ACTIVATED, {
    optimizely_experiment_key: experiment.key,
    optimizely_variation_key: variation.key,
  });

  window.dataLayer.push({
    'event': 'interaction',
    'target': 'optimizely full stack',
    'action': 'assign user id',
    'target-properties': userId,
  });
};

/**
 * Forces a variation for an experiment. Should only be used when the app
 * needs to ensure that events triggered by an user should always be sent
 * to one variation.
 *
 * This function is mostly used when theres the need to run A/B tests between
 * multiple shop applications.
 */
export const forceOptimizelyVariation = (
  experiment: string,
  optimizelyClient: ReactSDKClient,
  userId: string,
  attributes: UserAttributes,
  forcedVariation: string,
): void => {
  const variation = optimizelyClient.getVariation(experiment);

  logger.debug(
    `[OPTIMIZELY] - DEBUG - variation for user ${userId} in experiment ${experiment} is ${variation}.`,
  );

  if (!variation) {
    logger.debug(
      `[OPTIMIZELY] - DEBUG - no variation set for user ${userId}, the experiment ${experiment} is disabled.`,
    );
    return;
  }

  if (variation && variation !== forcedVariation) {
    logger.debug(
      `[OPTIMIZELY] - DEBUG - variation for experiment ${experiment} is different from the expected, forcing the correct variation ${forcedVariation} for user ${userId}`,
    );
    optimizelyClient.setForcedVariation(experiment, userId, forcedVariation);
  }

  optimizelyClient.activate(experiment, userId, attributes);
};

export const isFeatureFlagCookieEnabled = (cookieName: string): boolean => {
  const cookieVal = getCookie(cookieName);

  if (!cookieVal) {
    return undefined;
  }

  const valAsBoolean = JSON.parse(cookieVal) as boolean;

  return valAsBoolean;
};
