import { serialize } from 'cookie';
import type { NextApiResponse } from 'next';

type CookieSerializeOptions = {
  expires?: Date;
  maxAge?: number;
  secure?: boolean;
  sameSite?: 'strict' | 'lax' | 'none' | boolean;
};

type DefaultCookieDomains = {
  prod: string;
  preview: string;
  env: string;
};

/**
 * This sets `cookie` using the `res` object
 */
export const serializeCookie = (
  name: string,
  value: unknown,
  inputOptions: CookieSerializeOptions = {},
): string => {
  let options = { ...inputOptions, path: '/' };

  const stringValue =
    typeof value === 'object' ? `j:${JSON.stringify(value)}` : String(value);

  if (options.maxAge) {
    options = {
      ...options,
      expires: new Date(Date.now() + options.maxAge),
      maxAge: options.maxAge,
      secure: true,
    };
  }

  // none must be followed by secure true for certain browsers
  if (options.sameSite === 'none') {
    options = {
      ...options,
      secure: true,
    };
  }

  const serialized = serialize(name, String(stringValue), options);

  return serialized;
};

export const setCookie = (
  setHeader: NextApiResponse['setHeader'],
  name: string,
  value: unknown,
  defaultOptions: CookieSerializeOptions = {},
): void => {
  const cookie = serializeCookie(name, value, defaultOptions);

  setHeader('Set-Cookie', cookie);
};

const getCookieDomain = (defaultDomains: DefaultCookieDomains): string => {
  if (defaultDomains.env) {
    return defaultDomains.env;
  }

  const environment = process.env.ENVIRONMENT || 'preview';

  if (environment === 'production') {
    return defaultDomains.prod;
  }

  if (environment === 'preview') {
    return defaultDomains.preview;
  }

  return '';
};

export const getStoreCookieDomain = (): string =>
  getCookieDomain({
    prod: 'store.sumup.com',
    preview: '.sumup-vercel.app',
    env: process.env.NEXT_PUBLIC_SUMUP_COOKIE_DOMAIN,
  });

export const getSumupCookieDomain = (): string =>
  getCookieDomain({
    prod: '.sumup.com',
    preview: '.sumup-vercel.app',
    env: process.env.NEXT_PUBLIC_SUMUP_COOKIE_DOMAIN,
  });
